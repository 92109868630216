import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52')
];

export const server_loads = [0,16,18,2];

export const dictionary = {
		"/h/[[page]]/[confirm=confirmMatcher]": [~49,[16],[17]],
		"/h/[signup=signupMatcher]/[[recruitmentId]]": [50,[16,18],[17]],
		"/orgadmin/hubs": [51,[19]],
		"/orgadmin/organizations": [52,[19]],
		"/[hubPrefix=hubPrefixMatcher]/compliance/ccpa": [21,[2]],
		"/[hubPrefix=hubPrefixMatcher]/content/(manageContent)/comments": [24,[2,3]],
		"/[hubPrefix=hubPrefixMatcher]/content/newsletters/list": [26,[2,4,5]],
		"/[hubPrefix=hubPrefixMatcher]/content/newsletters/list/drafts": [27,[2,4,5]],
		"/[hubPrefix=hubPrefixMatcher]/content/newsletters/list/scheduled": [28,[2,4,5]],
		"/[hubPrefix=hubPrefixMatcher]/content/newsletters/list/sent": [29,[2,4,5]],
		"/[hubPrefix=hubPrefixMatcher]/content/newsletters/overview": [30,[2,4]],
		"/[hubPrefix=hubPrefixMatcher]/content/newsletters/overview/[overviewType=newslettersOverviewMatcher]": [31,[2,4]],
		"/[hubPrefix=hubPrefixMatcher]/content/(manageContent)/submissions": [25,[2,3]],
		"/[hubPrefix=hubPrefixMatcher]/content/(manageContent)/[contentType=manageContentTypeMatcher]": [22,[2,3]],
		"/[hubPrefix=hubPrefixMatcher]/content/(manageContent)/[postId]": [23,[2,3]],
		"/[hubPrefix=hubPrefixMatcher]/group/[type=groupTypeMatcher]": [32,[2]],
		"/[hubPrefix=hubPrefixMatcher]/notifications": [33,[2,6]],
		"/[hubPrefix=hubPrefixMatcher]/posts/[postSlug=endsOnInt]": [34,[2]],
		"/[hubPrefix=hubPrefixMatcher]/profile": [35,[2]],
		"/[hubPrefix=hubPrefixMatcher]/search": [36,[2]],
		"/[hubPrefix=hubPrefixMatcher]/settings/design/cover": [37,[2,7]],
		"/[hubPrefix=hubPrefixMatcher]/settings/design/header": [38,[2,7]],
		"/[hubPrefix=hubPrefixMatcher]/settings/design/text": [39,[2,7]],
		"/[hubPrefix=hubPrefixMatcher]/settings/hub/advanced": [40,[2,8]],
		"/[hubPrefix=hubPrefixMatcher]/settings/hub/feature-toggle": [41,[2,9]],
		"/[hubPrefix=hubPrefixMatcher]/settings/hub/moderator": [42,[2,10]],
		"/[hubPrefix=hubPrefixMatcher]/settings/hub/profile": [43,[2,11]],
		"/[hubPrefix=hubPrefixMatcher]/settings/hub/signin": [44,[2,12]],
		"/[hubPrefix=hubPrefixMatcher]/settings/integrations": [45,[2,13]],
		"/[hubPrefix=hubPrefixMatcher]/settings/member-data": [46,[2,14]],
		"/[hubPrefix=hubPrefixMatcher]/settings/user/profile-data": [48,[2,15]],
		"/[hubPrefix=hubPrefixMatcher]/settings/user/profile": [47,[2,15]],
		"/[hubPrefix=hubPrefixMatcher]/[...collection]": [20,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';